<template>
  <div class="min-h-screen text-red-900 flex justify-center dlbox" style="height: 100vh; position: relative">
    <div class="max-w-screen-xl m-0 sm:m-24 sm:rounded-lg flex justify-center flex-1"
      style="background-color: transparent">
      <div class="flex-1 text-center hidden lg:flex">
        <div>
          <div style="
              color: #fff;
              font-size: 23px;
              margin-top: 10vh;
              margin-left: 10vw;
              text-align: left;
            ">
            <h1 style="
                font-size: 60px;
                font-weight: 600;
                margin-bottom: 5vh;
                border-bottom: 5px solid #fff;
                letter-spacing: 10px;
              ">
              建<span style="font-size: 80px">筑</span><span style="color: #d1100b">+</span>共<span
                style="font-size: 80px">赢</span><span style="color: #d1100b">+</span>智慧云平<span
                style="font-size: 80px">台</span>
            </h1>
            <div style="letter-spacing: 5px">
              <h5 style="margin-bottom: 1vh">
                这里将呈现给<span style="font-size: 40px">您</span>的是：
              </h5>
              <ul style="margin-left: 20px">
                <li class="liNum">项目与任务的管理</li>
                <li class="liNum">场景化应用的生态工具箱</li>
                <li class="liNum">快速便捷的数据查询与统计分析</li>
                <li class="liNum">工作中的协同</li>
                <li class="liNum">云存储</li>
              </ul>
            </div>
            <div style="margin-top: 8vh; text-align: left">
              <el-button @click="goExperience" style="
                  letter-spacing: 5px;
                  font-size: 23px;
                  color: #fff;
                  background: #d1100b;
                  border: 1px solid #d1100b;
                  font-weight: 600;
                " round>去体验
              </el-button>
              <el-button @click="showRegister(false)" style="font-size: 23px; color: #000; font-weight: 600" round>去注册
                ->
              </el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="eltabs" class="lg:w-1/2 xl:w-3/12" style="margin: 180px 80px 0 0">
      <div style="border-radius: 15px; overflow: hidden">
        <el-tabs type="border-card" :stretch="true">
          <el-tab-pane>
            <p slot="label">
              <i style="cursor: pointer; width: 25px; height: 25px" class="el-icon-s-custom"></i>
              <span style="margin-left: 5px; line-height: 40px">账号登录</span>
            </p>
            <div class="mt-12 flex flex-col items-center">
              <div style="width: 100%">
                <div v-loading="loading" class="loginAndRegister">
                  <!-- 登录 -->
                  <div v-if="showPrise && NoToken" class="box1">
                    <div id="login">
                      <div style="
                          display: flex;
                          align-items: center;
                          justify-content: space-between;
                        ">
                        <h2 style="
                            margin: 0;
                            font-size: 24px;
                            letter-spacing: 2px;
                            font-weight: bold;
                          ">
                          欢迎登录
                        </h2>
                        <el-link :underline="false" @click="showRegister(false)">注册账号</el-link>
                      </div>
                      <el-form ref="ruleForm1" :model="ruleForm1" :rules="rules1" @submit.native.prevent>
                        <el-form-item prop="Number">
                          <el-input v-model="ruleForm1.Number" auto-complete="off" placeholder="请输入手机号"
                            prefix-icon="el-icon-mobile-phone"></el-input>
                        </el-form-item>
                        <el-form-item prop="Cipher">
                          <el-input v-model="ruleForm1.Cipher" auto-complete="off" placeholder="输入密码"
                            prefix-icon="el-icon-edit" show-password type="password"></el-input>
                        </el-form-item>
                        <el-form-item prop="TiaoKuanLogin">
                          <p>
                            <el-checkbox v-model="checked" class="tiaokuan">我已阅读并同意
                              <router-link :to="{ path: '/NoT/Article' }" style="color: #d1100b; text-decoration: none"
                                target="_blank">
                                《服务协议》
                              </router-link>
                              <router-link :to="{ path: '/NoT/Privacys' }" style="color: #d1100b; text-decoration: none"
                                target="_blank">
                                《隐私政策指引》
                              </router-link>
                            </el-checkbox>
                          </p>
                        </el-form-item>
                        <el-form-item class="btn1" style="margin-bottom: 6vh" prop="Login">
                          <el-button native-type="submit" size="mini" style="
                              width: 100%;
                              justify-content: center;
                              font-size: 20px;
                              font-weight: 600;
                              letter-spacing: 20px;
                              border-radius: 20px;
                            " type="primary" :disabled="!checked" @click="loginForm">登录
                          </el-button>
                        </el-form-item>
                        <!-- <el-form-item>

                        </el-form-item> -->
                      </el-form>
                    </div>
                  </div>
                  <!--
                                   <div style="-->
                  <!--                                display: flex;-->
                  <!--                                flex-direction: column;-->
                  <!--                                align-items: center;-->
                  <!--                              ">-->
                  <!--                              <span>IOS</span>-->
                  <!--                              <vue-qr :logoSrc="imageUrl" :text="codeValueIOS" :logoScale="0.2" :size="150"-->
                  <!--                                style="margin: 0 auto; padding: 0"></vue-qr>-->
                  <!--                            </div>
 -->
                  <!-- 注册 -->
                  <div v-if="!showPrise && NoToken" class="box2">
                    <div id="register">
                      <div style="
                          display: flex;
                          align-items: center;
                          justify-content: space-between;
                          margin-bottom: 30px;
                        ">
                        <h2 style="
                            margin: 0;
                            font-size: 24px;
                            letter-spacing: 2px;
                            font-weight: bold;
                          ">
                          {{ !unionid ? "账号注册" : "绑定手机号" }}
                        </h2>
                        <el-button size="mini" type="text" @click="showRegister(true)">已有账号?去登录
                        </el-button>
                      </div>
                      <el-form ref="ruleForm" :model="ruleForm2" :rules="rules2">
                        <el-form-item prop="tel" style="position: relative">
                          <el-input v-model="ruleForm2.tel" :disabled="!Codedisabled" auto-complete="off"
                            placeholder="请输入手机号" prefix-icon="el-icon-mobile-phone"></el-input>
                        </el-form-item>
                        <el-form-item v-show="!Codedisabled" prop="word">
                          <el-input v-model="ruleForm2.word" auto-complete="off" placeholder="手机验证码"
                            prefix-icon="el-icon-tickets" style="margin-top: -10px">
                          </el-input>
                        </el-form-item>
                        <el-form-item v-if="!unionid" v-show="!Codedisabled" prop="pass">
                          <el-input v-model="ruleForm2.pass" :type="flag ? 'text' : 'password'" auto-complete="off"
                            placeholder="输入密码" prefix-icon="el-icon-edit">
                            <i slot="suffix" :class="flag ? 'el-icon-minus' : 'el-icon-view'" autocomplete="auto"
                              style="margin-top: 8px; font-size: 18px" @click="flag = !flag" />
                          </el-input>
                        </el-form-item>
                        <el-form-item v-if="!unionid" v-show="!Codedisabled" prop="checkPass">
                          <el-input v-model="ruleForm2.checkPass" :type="resetflag ? 'text' : 'password'"
                            auto-complete="off" placeholder="确认密码" prefix-icon="el-icon-edit">
                            <i slot="suffix" :class="resetflag ? 'el-icon-minus' : 'el-icon-view'
                              " autocomplete="auto" style="margin-top: 8px; font-size: 18px"
                              @click="resetflag = !resetflag" />
                          </el-input>
                        </el-form-item>
                        <el-form-item prop="TiaoKuanRegister">
                          <p>
                            <el-checkbox v-show="Codedisabled" v-model="checked" class="tiaokuan">我已阅读并同意
                              <router-link :to="{ path: '/NoT/Article' }" style="color: #d1100b; text-decoration: none"
                                target="_blank">
                                《服务协议》
                              </router-link>
                              <router-link :to="{ path: '/NoT/Privacys' }" style="color: #d1100b; text-decoration: none"
                                target="_blank">
                                《隐私政策指引》
                              </router-link>
                            </el-checkbox>
                          </p>
                        </el-form-item>
                        <el-form-item prop="SendMsg">
                          <el-button size="mini" :disabled="!checked" style="
                              width: 100%;
                              justify-content: center;
                              font-size: 20px;
                              font-weight: bold;
                              letter-spacing: 20px;
                              border-radius: 20px;
                            " type="primary" @click="
                              submitForm(Codedisabled ? '发送验证码' : '注册')
                              ">{{ Codedisabled ? "发送验证码" : "注册" }}
                          </el-button>
                        </el-form-item>
                      </el-form>
                    </div>
                  </div>

                  <!-- 直接进入 -->
                  <div v-if="!NoToken" class="box1">
                    <div id="directEntry">
                      <div style="
                          display: flex;
                          align-items: center;
                          justify-content: space-between;
                        ">
                        <h2 style="margin: 0">欢迎进入</h2>
                        <el-button size="mini" type="text" @click="showLogin(false)">注销登录
                        </el-button>
                      </div>
                      <el-form ref="ruleForm3">
                        <el-form-item prop="avatar">
                          <el-avatar :size="150" :src="op().Icon" class="avatar" fit="fill" shape="circle"></el-avatar>
                        </el-form-item>
                        <el-form-item class="btn1" prop="directLogin">
                          <el-button size="mini" style="
                              width: 95%;
                              margin-left: 2.5%;
                              font-size: 19px;
                              font-weight: 600;
                              border-radius: 20px;
                            " type="primary" @click="DirectLogin">
                            立即进入
                          </el-button>
                        </el-form-item>
                        <el-form-item prop="TiaoKuanDirect">
                          <p>
                            <el-checkbox v-model="checked" class="tiaokuan" style="padding: 0px 0px 4% 0px">
                              我已阅读并同意
                              <router-link :to="{ path: '/' }" style="color: #d1100b; text-decoration: none"
                                target="_blank">
                                使用条款
                              </router-link>
                            </el-checkbox>
                          </p>
                        </el-form-item>
                      </el-form>
                    </div>
                  </div>

                  <el-dialog :close-on-click-modal="false" :visible.sync="dialogVisible" title="请输入图形验证码" width="30%">
                    <div style="display: flex; align-items: center">
                      <el-input v-model="ruleForm2.smscode" placeholder="验证码" prefix-icon="el-icon-picture-outline "
                        style="width: calc(100% - 120px)"></el-input>
                      <div style="width: 120px; height: 40px" @click="yzmclick">
                        <Yzm :identifyCode="key" @strchange="strchange"></Yzm>
                      </div>
                    </div>
                    <span slot="footer" class="dialog-footer">
                      <el-button size="mini" @click="dialogVisible = false">取 消</el-button>
                      <el-button size="mini" type="primary" @click="getCode">确 定</el-button>
                    </span>
                  </el-dialog>
                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane>
            <p slot="label">
              <img alt style="cursor: pointer; width: 25px; height: 25px" src="@/assets/微信.png" />
              <span style="margin-left: 5px; line-height: 40px">微信登录</span>
            </p>
            <div class="mt-12 flex flex-col items-center">
              <div style="width: 100%">
                <div v-loading="loading" class="loginAndRegister">
                  <div style="display: flex; justify-content: center">
                    <wxlogin id="login_container" :appid="appid" :redirect_uri="getRedirect_uri()" :scope="scope"
                      href="" state="STATE" style=""></wxlogin>
                  </div>
                </div>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <el-dialog title="" :visible.sync="showLoad" :show-close="false" class="qrcodebox">
      <div style="justify-content: center">
        <div class="qrcode" ref="qrCodeUrl"></div>
        <div style="display: flex; flex-wrap: wrap; justify-content: center">
          <div style="display: flex; flex-direction: column; align-items: center ">
            <vue-qr :logoSrc="imageUrl" :text="codeValue" :logoScale="0.2" :size="300"
              style="margin: 0 auto; padding: 0"></vue-qr>
          </div>
        </div>
        <p class="excitation-span" style="font-weight: bold">
          打开微信或浏览器扫描二维码下载
          <span style="color: #d1100b">巡检GO</span> 客户端
        </p>
      </div>
    </el-dialog>
    <div style="
        position: absolute;
        top: 0;
        width: 100vw;
        background: #fff;
        height: 10vh;
      "></div>
    <div class="Newlogo" style="
        width: 12vw;
        height: 10vh;
        padding: 0 0px;
        background-color: #fff;
        position: absolute;
        left: 10vw;
        display: flex;
        justify-content: space-around;
        align-items: center;
      ">
      <img style="height: 60%" src="../../assets/筑赢台logo/正常.png" alt="" />
      <img style="height: 40%; margin-left: -5px" src="../../assets/筑赢台logo/zytlogozi.jpg" alt="" />
    </div>
    <div style="position: absolute; font-weight: 600; right: 13vw; top: 3vh" class="appload">
      <el-button style="
          color: #fff;
          background: #d1100b;
          border: none;
          font-weight: 600;
          font-size: 23px;
        " @click="() => (showLoad = true)" round>APP下载
      </el-button>
    </div>
    <div class="footer">
      <p>
        <span style="font-weight: bold">ICP备案号：</span><span style="margin-right: 20px">京ICP备2021033771号</span>
        <span style="font-weight: bold">技术支持<i class="el-icon-phone"
            style="color: red;"></i>：</span><span>13120268450</span>
      </p>
      <p>
        <span style="font-weight: bold">Copyright &copy;</span><span style="margin-right: 20px">2021-{{ new
          Date().getFullYear() }}</span>
        <span style="font-weight: bold">版权所有：</span><span>铂诺客智能科技（北京）有限公司</span>
      </p>
    </div>
  </div>
</template>

<script>
import OperatorCache from "@/utils/cache/OperatorCache";
import TokenCache from "@/utils/cache/TokenCache";
import { mapState } from "vuex";
import Yzm from "@/components/list.vue";
import vueQr from "vue-qr";
import wxlogin from "vue-wxlogin";
import "@/assets/Index/index.css";

let checkLoginPhone = (rule, value, callback) => {
  let reg = /^1[345789]\d{9}$/;
  if (!reg.test(value)) {
    callback(new Error("请输入11位手机号"));
  } else {
    callback();
  }
};
let checkRegisterPhone = (rule, value, callback) => {
  let reg = /^1[345789]\d{9}$/;
  if (!reg.test(value)) {
    callback(new Error("请输入11位手机号"));
  } else {
    callback();
  }
};
export default {
  name: "Index",
  props: ["centerDialogVisibleSecond"],
  components: {
    vueQr,
    Yzm,
    wxlogin,
  },
  data() {
    // 登录
    const validatecip = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.ruleForm1.checkPass !== "") {
          this.$refs.ruleForm1.validateField("checkPass");
        }
        callback();
      }
    };

    // 注册
    const validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.ruleForm2.pass) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      showLoad: false,
      codeValue: document.location.origin + "/XunJianGo",
      codeValueIOS: document.location.origin + "/XunJianGoIOS",
      imageUrl: require("@/assets/XunJianGo_Logo.png"), //icon路径
      loading: false,
      loginAndRegisterShow: false,
      showPrise: true,
      showRentPrise: true,
      NoToken: true, //无Token
      ruleForm1: {
        Number: "",
        Cipher: "",
      },
      rules1: {
        Number: [
          {
            required: true,
            message: "请输入手机号码",
            validator: checkLoginPhone,
            trigger: "blur",
          },
        ],
        Cipher: [
          {
            validator: validatecip,
            required: true,
            message: "请输入密码",
            trigger: "blur",
          },
        ],
      },
      ruleForm2: {
        smscode: "",
        tel: "",
        word: "",
        pass: "",
        checkPass: "",
      },
      rules2: {
        smscode: [
          { required: true, message: "请输入图片验证码", trigger: "blur" },
        ],
        tel: [
          {
            required: true,
            message: "请输入手机号码",
            validator: checkRegisterPhone,
            trigger: "blur",
          },
        ],
        word: [
          { required: true, message: "请输入手机验证码", trigger: "blur" },
        ],
        pass: [{ required: true, message: "请输入密码", trigger: "blur" }],
        checkPass: [
          { required: true, validator: validatePass, trigger: "blur" },
        ],
      },
      dialogVisible: false,
      dialogVisibleDownload: false,
      timer: null,
      oneMinute: 60,
      flag: false, // 密码可视
      resetflag: false, // 密码可视
      checked: true,
      disabled: true,
      Codedisabled: true,
      buttonText: "",
      isDisabled: false,
      key: "",
      url: "",
      appid: "wxef625270eda5492e",
      scope: "snsapi_login",
      codeData: {},
      unionid: null,
    };
  },
  methods: {
    // app下载
    // loadApp() {
    //   this.$message.
    // },
    getTitle() {
      return process.env.VUE_APP_ProjectName;
    },
    op() {
      return this.userInfo;
    },
    redirectUrl(value) {
      if (value === "index") {
        this.$router.replace({ path: "/Home/Index" });
      }
      if (value === "login") {
        if (this.$refs["ruleForm1"]) {
          this.$nextTick(() => {
            this.$refs["ruleForm1"].clearValidate();
          });
        }
        this.loginAndRegisterShow = true;
        this.showPrise = true;
        this.Codedisabled = true;
      }
      if (value === "register") {
        if (this.$refs["ruleForm"]) {
          this.$nextTick(() => {
            this.$refs["ruleForm"].clearValidate();
          });
        }
        this.loginAndRegisterShow = true;
        this.showPrise = false;
        // this.Codedisabled = false
      }
      if (value === "Home") {
        this.loginAndRegisterShow = true;
        this.showPrise = true;
        this.Codedisabled = true;
      }
    },
    handleLogout() {
      const that = this;
      this.$confirm({
        title: "提示",
        content: "真的要注销登录吗 ?",
        onOk() {
          TokenCache.deleteToken();
          OperatorCache.clear();
          location.reload();
          that.$router.replace({ path: "/Home/Index" });
        },
      });
    },
    showRegister(showPrise) {
      this.showPrise = showPrise;
      this.Codedisabled = true;
      this.ruleForm2.tel = "";
      this.$nextTick(() => {
        if (this.$refs["ruleForm1"]) {
          this.$refs["ruleForm1"].clearValidate();
        }
        if (this.$refs["ruleForm"]) {
          this.$refs["ruleForm"].clearValidate();
        }
      });
    },

    showLogin() {
      TokenCache.deleteToken();
      OperatorCache.clear();
      this.$router.replace({ path: "/Home/Index" });
      location.reload();
    },
    // 直接进入
    DirectLogin() {
      this.$router.replace({ path: "/Project/All" });
    },
    loginAndRegisterClose(done) {
      done();
      this.loginAndRegisterShow = false;
      this.ruleForm1 = {};
      this.ruleForm2 = {};
    },
    //去体验
    goExperience() {
      this.showRegister(true);
      this.$http.post("/Base_Manage/Home/ResetTestAccountInfo");
      this.ruleForm1.Number = "18123456789";
      this.ruleForm1.Cipher = "123456";
      this.$message.success("账号密码已填写，请点击登陆体验");
    },
    loginForm() {
      this.$refs.ruleForm1.validate((valid) => {
        if (!valid) {
          return;
        }
        this.loading = true;
        this.$http
          .post("/Base_Manage/Home/AppSubmitLogin", {
            userName: this.ruleForm1.Number,
            password: this.ruleForm1.Cipher,
          })
          .then(async (res) => {
            this.loading = false;
            if (res.Success) {
              TokenCache.setToken(res.Data);
              await this.$store.dispatch("getUserInfo");
              //设置Vuex登录标志为true，默认userLogin为false
              await this.$store.dispatch("setUser", true);
              this.$router.replace({ path: "/Project/All" });
            } else {
              this.$message.error(res.Msg);
            }
          });
      });
    },
    submitForm(type) {
      // 验证码发送
      if (type === "发送验证码") {
        if (!this.checked) return this.$message.error("请勾选同意使用条款");
        this.$refs.ruleForm.validateField("tel", async (valid) => {
          if (valid) {
            return;
          }
          if (!this.unionid) {
            let result = await this.$http.post(
              "Base_Manage/Home/ValidateVIPUserPhone?phone=" +
              this.ruleForm2.tel
            );
            if (result.Success && !result.Data) {
              this.showPrise = true;
              this.Codedisabled = true;
              return this.$message.error("当前手机号已完成注册");
            }
          }

          this.dialogVisible = true;
        });
        return;
      }
      // 注册
      this.$refs.ruleForm.validate((valid) => {
        if (!valid) {
          this.$message.error({
            showClose: true,
            message: "请填写完成后注册",
            type: "error",
            duration: 0,
          });
          // this.$message.error("请填写完成后注册");
          return;
        }
        this.$http
          .post("/Base_Manage/Home/AddVIPUserInfoData", {
            Phone: this.ruleForm2.tel,
            Password: this.ruleForm2.checkPass,
            SmsCode: this.ruleForm2.word,
            unionid: this.unionid || "",
            openid: this.openid || "",
            Name: this.ruleForm2.tel,
            NickName: this.ruleForm2.tel,
            ...this.codeData,
          })
          .then(async (res) => {
            if (res.Success) {
              this.$message.success(res.Msg);
              this.showPrise = true;
              this.showRentPrise = false;
              TokenCache.setToken(res.Data);
              location.reload();
              this.$router.replace("/Project/All");
            } else {
              this.$message.error(res.Msg);
            }
          });
      });
    },
    getCode() {
      if (this.ruleForm2.smscode.toUpperCase() === this.key.toUpperCase()) {
        this.dialogVisible = false;
        this.Codedisabled = false;

        this.$http.post(
          '/Base_Manage/Base_SMSConfig/GetTemplateCodeByCode?code=' + 'ZYT_VIPUser_Register'
        ).then(codeData => {
          if (codeData.Success) {
            this.$http
              .post(
                "/Base_Manage/Home/GetShortMessage?Phone=" +
                this.ruleForm2.tel +
                "&TemplateCode=" + codeData.Data
              )
              .then((res) => {
                if (res.Success) {
                  this.codeData = JSON.parse(res.Data);
                  this.$message.success("验证码已经发送至您的手机,请注意查收");
                } else {
                  this.$message.error(res.Msg);
                }
              });
          } else {
            this.$message.error(codeData.Msg);
          }
        })

      } else {
        this.$message.error("请输入正确的验证码");
      }
    },
    yzmclick() {
      this.getYzm();
    },
    getYzm() {
      var str =
        "qwertyuioplkjhgfdsazxcvbnm1234567890QWERTYUIOPLKJHGFDSAZXCVBNM";
      var Yzm = "";
      for (var i = 0; i <= 3; i++) {
        var item = parseInt(Math.random() * (str.length - 1 + 0 + 1) - 0);
        Yzm += str[item];
      }
      this.key = Yzm;
    },
    strchange(val) {
      this.key = val;
    },
    smscodechange(text) {
      if (text.toUpperCase() === this.key.toUpperCase()) {
        this.disabled = false;
      } else {
        this.disabled = true;
      }
    },
    // 回调地址获取
    getRedirect_uri() {
      let protocolStr = document.location.protocol;
      return encodeURIComponent(protocolStr + "//www.zytsaas.com/Home/Index");
    },
    // 微信登录
    wxLoginApi(openid, unionid) {
      console.log("wxLoginApi");
      this.$http
        .post("/Base_Manage/Base_VIPUserInfo/GetVIPUserInfoData", {
          openid,
          unionid,
        })
        .then(async (res) => {
          this.loading = false;
          if (res.Success) {
            if (res.Data.Token) {
              TokenCache.setToken(res.Data.Token);
              await this.$store.dispatch("getUserInfo");
              //设置Vuex登录标志为true，默认userLogin为false
              await this.$store.dispatch("setUser", true);
              this.$router.replace({ path: "/Project/All" });
            } else {
              this.loginAndRegisterShow = true;
              this.showPrise = false;
              this.$message.error("当前微信号尚未关联手机号,请进行注册");
            }
          } else {
            this.loginAndRegisterShow = true;
            this.showPrise = false;
            this.$message.error("当前微信号尚未关联手机号,请进行注册");
          }
        });
    },
  },
  mounted() {
    this.getYzm();
  },
  created() {
    //下载二维码
    this.$nextTick(() => {
      this.codeValue = document.location.origin + "/XunJianGo";
    });
  },
  computed: {
    ...mapState(["userInfo", "isLogin"]),
  },
};
</script>

<style lang="scss" scoped>
.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  background-color: #ffffff;
  /* 设置背景色 */
  padding: 8px 0;
  /* 设置内边距 */
  font-size: 14px;
  /* 设置字体大小 */
  color: #666;
  /* 设置字体颜色 */
}

.dlbox {
  background-image: url("../../assets/homebg1.jpg"),
    linear-gradient(to bottom right, #644ded, #811389) !important;
  background-size: 100% 95%;
  background-repeat: no-repeat;
}

.dlbox ::v-deep .el-tabs__item {
  height: 50px !important;
  line-height: 50px !important;
  font-size: 18px !important;
  letter-spacing: 2px !important;
}

::v-deep .qrcodebox .el-dialog {
  position: relative;
  margin: 0 auto 50px;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  width: 35%;
  padding: 0 0 43px 0;
}

/* 登录注册 */
.box1 .tiaokuan {
  display: block;
  justify-content: center;
  align-items: center;
}

.el-input--prefix .el-input__inner {
  border-radius: 18px;
}

.mark .box1 .btn1[data-v-fae5bece] {
  font-size: 20px;
}

//loginbox
.loginAndRegister {
  width: 100%;
  // min-height: 63vh;
  margin: 0 auto;

  .box1,
  .box2 {
    background-color: #ffffff;
    width: 100%;
    height: auto;
    margin: 0 auto;
    padding: 0 30px;
  }

  .box1 .el-form-item {
    margin-top: 20px;
  }

  .box1 .btn1 {
    font-size: 20px;
  }

  .box1 .el-form-item .imgs1 {
    width: 37px;
    margin-top: 11px;
    margin-left: -55%;
  }

  .box1 .Back {
    margin-left: 75%;
    color: #d1100b;
    font-size: 20px;
    display: flex;
    margin-top: -14%;
    cursor: pointer;
  }

  .box1 .revise {
    margin-left: 79%;
    color: rgb(95, 170, 255);
    font-size: 20px;
    display: flex;
    margin-top: -11.5%;
    cursor: pointer;
  }
}

.liNum {
  list-style-type: disc;
  text-indent: 20px;
}
</style>
<style>
@keyframes banner {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-60vw);
  }
}
</style>
